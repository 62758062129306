import { css } from '@emotion/react'
import type { ScheduledTermination } from '@orus.eu/backend/src/events/subscription-event/protection-schedule-updated-event'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import type { CalendarDate } from '@orus.eu/calendar-date'
import { calendarDateToDateTime, formatTimestampDdMmYyyy, PARIS } from '@orus.eu/calendar-date'
import { isEmptyOrBlankSpaces } from '@orus.eu/char'
import { TechnicalError } from '@orus.eu/error'
import {
  Button,
  colorTokens,
  ContentContainerBackoffice,
  DatePicker,
  Dialog,
  DropdownFormField,
  PersistentNotification,
  Select,
  spaceBetweenFormFields,
  spacing,
  Text,
  TextField,
  TextInputLabelWrapper,
  useAsyncCallback,
  useEnqueueToast,
  ValidatedTextField,
  type ValidatedTypeMapper,
} from '@orus.eu/pharaoh'
import type { NotificationVariant } from '@orus.eu/pharaoh/src/components/callout-notification/notification/util'
import type {
  ProtectionStatus,
  ProtectionStatusChange,
  TerminationReason,
  UpdatedProtectionStatus,
} from '@orus.eu/protection'
import {
  getProtectionStatusAtTime,
  isProtectionStatus,
  isTerminatedStatus,
  isTerminationReason,
  terminationReasonValues,
} from '@orus.eu/protection'
import { failure, success } from '@orus.eu/result'
import { useParams } from '@tanstack/react-router'
import type { ReactElement } from 'react'
import { useReducer, useState, type Dispatch, type FormEvent } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { formatTimestamp } from '../../../../lib/format'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { noop } from '../../../../lib/noop'
import { oneMinute, useCurrentTimestamp } from '../../../../lib/use-current-timestamp'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'

const rightAlertBannerCardWidth = '450px'
const statusEditorFormMinWidth = '480px'

export default function PlatformChangeContractStatusPage(): ReactElement {
  const { subscriptionId } = useParams({ from: '/bak/contracts/$subscriptionId/status' })
  const [contract] = trpcReact.contracts.getContract.useSuspenseQuery(subscriptionId)

  return <BackofficeChangeContractStatusLoadedPage contract={contract} />
}

type ContractStatusInformationBanner = {
  title: string
  /**
   * The content string is rendered as Markdown
   */
  contentMd: string
  role: NotificationVariant
}

type ChangeContratStatusState = {
  inputSetId: string
  selectedStatus: UpdatedProtectionStatus | null
  effectDate: CalendarDate | null
  comment: string | null
  protectionHistory: ProtectionStatusChange[]
}

function getInitialPageState(contract: BackofficeContractDescription): ChangeContratStatusState {
  return {
    inputSetId: contract.subscriptionId,
    selectedStatus: null,
    effectDate: null,
    comment: null,
    protectionHistory: contract.protectionHistory,
  }
}

const protectionStatusBanners: { [Status in ProtectionStatus]: ContractStatusInformationBanner } = {
  'not-started': {
    title: 'Un contrat actif',
    role: 'info',
    contentMd:
      'Tout se passe pour le mieux, les garanties couvrent normalement le contrat du client, il est éligible à toutes les services et fonctionnalités. ',
  },
  active: {
    title: 'Un contrat actif',
    role: 'info',
    contentMd:
      'Tout se passe pour le mieux, les garanties couvrent normalement le contrat du client, il est éligible à toutes les services et fonctionnalités. ',
  },
  suspended: {
    title: 'Un contrat suspendu',
    role: 'info',
    contentMd:
      'Il est possible que le client soit pas mal en retard pour le règlement de ses factures ou que malheureusement nous devons bloquer sa couverture de garanties.\n' +
      '\n' +
      'Suspension du contrat après 40 jours sans paiement à date de l’échéance.',
  },
  terminated: {
    title: 'Un contrat résilié',
    role: 'warning',
    contentMd:
      'Ultime recours qui entraine la résiliation définitive du contrat et de ses garanties. \n' +
      'En cas d’impossibilité de recouvrement des impayés ou suite à une fraude ou d’exclusion.',
  },
}

type SelectStatusAction =
  | { type: 'select-status'; newStatus: ProtectionStatus; initialStatus: ProtectionStatus }
  | { type: 'update-comment'; newComment: string }
  | { type: 'update-effect-date'; newEffectDate: CalendarDate | null }

function changeContractStatusReducer(
  state: ChangeContratStatusState,
  action: SelectStatusAction,
): ChangeContratStatusState {
  const actionType = action.type
  switch (actionType) {
    case 'select-status':
      return {
        ...state,
        // "not-started" is the only state it's not possible to update to. So if "not-started" is selected
        // it means the user went back to the initial value of the select
        selectedStatus:
          action.newStatus === 'not-started'
            ? null
            : action.newStatus !== action.initialStatus
              ? action.newStatus
              : null,
      }
    case 'update-comment':
      return { ...state, comment: action.newComment }
    case 'update-effect-date':
      return { ...state, effectDate: action.newEffectDate }
  }
}

type BackofficeChangeContractStatusLoadedPage = {
  contract: BackofficeContractDescription
}

function BackofficeChangeContractStatusLoadedPage({
  contract,
}: BackofficeChangeContractStatusLoadedPage): ReactElement {
  const { enqueueToast } = useEnqueueToast()
  const currentTimestamp = useCurrentTimestamp(oneMinute)
  const [pageState, dispatch] = useReducer(changeContractStatusReducer, getInitialPageState(contract))
  const [terminationConfirmationModalShown, setTerminationConfirmationModalShown] = useState(false)
  const [submissionStatus, setSubmissionStatus] = useState<'idle' | 'pending'>('idle')

  const mutaSelected = contract.selectedProductWithQuote.mutaSelected!

  const initialProtectionStatus = getProtectionStatusAtTime(contract.protectionHistory, currentTimestamp)
  const initialStatus = initialProtectionStatus.currentStatus
  const initialEffectTimestamp = initialProtectionStatus.fromTimestamp

  const { effectDate, comment, selectedStatus, inputSetId: subscriptionId } = pageState

  const navigateToContract = useNavigateTo({ to: '/bak/contracts/$subscriptionId', params: { subscriptionId } })

  const submitEnabled =
    effectDate !== null &&
    comment !== null &&
    initialStatus !== selectedStatus &&
    selectedStatus !== null &&
    submissionStatus !== 'pending'

  const submitProtectionScheduleUpdate = useAsyncCallback(async () => {
    if (!submitEnabled) {
      return
    }

    setSubmissionStatus('pending')
    const protectionScheduleUpdate = getProtectionScheduleUpdate({
      selectedStatus,
      effectDate,
      reason: comment,
    })
    await trpc.contracts.updateProtectionSchedule.mutate({ subscriptionId, ...protectionScheduleUpdate })
    alert(`Le status du contrat a bien été modifié à '${protectionStatusLabels[selectedStatus]}'`)
    navigateToContract()
  }, [comment, effectDate, subscriptionId, navigateToContract, selectedStatus, submitEnabled])

  const handleSubmit = useAsyncCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      // if ask for contract termination, open modal to ask for confirmation
      if (selectedStatus === 'terminated') {
        setTerminationConfirmationModalShown(true)
        return
      }
      submitProtectionScheduleUpdate()
    },
    [selectedStatus, submitProtectionScheduleUpdate],
  )

  const scheduledTerminationsQuery = trpcReact.contracts.getScheduledTerminationTimestamps.useQuery(subscriptionId, {
    enabled: true,
  })

  const onCancelScheduledTermination = useAsyncCallback(
    async (scheduledTerminationTimestamp: number) => {
      await trpc.contracts.cancelScheduledTermination.mutate({
        subscriptionId,
        targetedTimestamp: scheduledTerminationTimestamp,
      })
      enqueueToast('La résiliation programmée a été annulée', { variant: 'success' })
      await scheduledTerminationsQuery.refetch()
    },
    [subscriptionId, enqueueToast, scheduledTerminationsQuery],
  )

  const scheduledTerminations = scheduledTerminationsQuery.data

  const isTerminationStatusUpdateDeadlinePassedQuery =
    trpcReact.contracts.isTerminationStatusUpdateDeadlinePassed.useQuery(initialEffectTimestamp, {
      enabled: initialStatus === 'terminated',
    })

  const isTerminationStatusUpdateDeadlinePassed =
    initialStatus === 'terminated' && isTerminationStatusUpdateDeadlinePassedQuery.data

  if (isTerminationStatusUpdateDeadlinePassed === undefined) {
    return <GlobalLoadingState />
  }

  const isStatusEditable = !(initialStatus === 'terminated' && mutaSelected) && !isTerminationStatusUpdateDeadlinePassed

  return (
    <ContentContainerBackoffice>
      <div
        css={css`
          margin: auto;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[70]};
          `}
        >
          <form onSubmit={handleSubmit}>
            <Header
              contract={contract}
              submitEnabled={submitEnabled}
              isStatusEditable={isStatusEditable}
              goBack={navigateToContract}
            />
            {isStatusEditable ? (
              <div
                css={css`
                  display: flex;
                  margin-top: ${spacing[70]};
                  gap: ${spacing[70]};
                `}
              >
                <div
                  css={css`
                    flex-grow: 1;
                  `}
                >
                  <StatusEditorCard
                    pageState={pageState}
                    dispatch={dispatch}
                    initialStatus={initialStatus}
                    isTerminationStatusUpdateDeadlinePassed={isTerminationStatusUpdateDeadlinePassed}
                  />
                </div>
                <div
                  css={css`
                    width: ${rightAlertBannerCardWidth};
                  `}
                >
                  <InformationCard selectedStatus={selectedStatus} initialStatus={initialStatus} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </form>
          <div>
            {scheduledTerminations && scheduledTerminations.length > 0 ? (
              scheduledTerminations.map((terminationTimestamp) => (
                <PersistentNotification
                  variant="danger"
                  title="Résiliation programmée"
                  actionButton={{
                    label: 'Annuler la résiliation programmée',
                    onClick: () => onCancelScheduledTermination(terminationTimestamp),
                  }}
                  key={terminationTimestamp}
                  css={css`
                    margin-top: ${spacing[30]};
                  `}
                >
                  <Text variant="body2">
                    Une résiliation est programmée pour le {formatTimestampDdMmYyyy(terminationTimestamp)}.
                  </Text>
                </PersistentNotification>
              ))
            ) : (
              <></>
            )}
          </div>

          {!isStatusEditable ? (
            <PersistentNotification variant="danger" title="Impossibilité de modifier le statut du contrat">
              {initialStatus === 'terminated' && mutaSelected ? (
                <Text variant="body2">
                  Ce contrat qui contient une mutuelle a été résilié. Son statut ne peut plus être modifié.
                </Text>
              ) : (
                <Text variant="body2">
                  Le délai de 7 jours après la résiliation du contrat est écoulé, il n&apos;est plus possible de
                  modifier le statut.
                </Text>
              )}
            </PersistentNotification>
          ) : (
            <></>
          )}

          {terminationConfirmationModalShown && effectDate ? (
            <TerminationConfirmationModal
              terminationDate={effectDate}
              handleClose={() => setTerminationConfirmationModalShown(false)}
              handleConfirm={submitProtectionScheduleUpdate}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </ContentContainerBackoffice>
  )
}

type TerminationConfirmationModalProps = {
  terminationDate: CalendarDate
  handleClose: () => void
  handleConfirm: () => void
}

function TerminationConfirmationModal(props: TerminationConfirmationModalProps): ReactElement {
  const title = 'Dernière étape avant la résiliation du contrat'
  const terminationDateTxt = formatTimestamp(calendarDateToDateTime(props.terminationDate, PARIS).toMillis())

  return (
    <Dialog
      size="small"
      style="danger"
      title={title}
      onClose={props.handleClose}
      onPrimaryAction={props.handleConfirm}
      primaryActionLabel="Résilier le contrat"
      onSecondaryAction={props.handleClose}
      secondaryActionLabel="Annuler"
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[30]};
        `}
      >
        <Text variant="body2Medium" element="p">{`Résiliation le ${terminationDateTxt}`}</Text>
        <Text variant="body2" element="p">
          La résiliation est ferme et définitive. Aucun changement sur le contrat ne sera possible après.
        </Text>
      </div>
    </Dialog>
  )
}

type InformationCardProps = {
  selectedStatus: UpdatedProtectionStatus | null
  initialStatus: ProtectionStatus
}

function InformationCard({ selectedStatus, initialStatus }: InformationCardProps) {
  const banner = protectionStatusBanners[selectedStatus ?? initialStatus]

  if (banner === null) {
    return <></>
  }
  return (
    <PersistentNotification variant={banner.role} title={banner.title}>
      {banner.contentMd}
    </PersistentNotification>
  )
}

const protectionStatusLabels: { [Status in ProtectionStatus]: string } = {
  'not-started': 'Prochainement actif',
  active: 'Actif',
  suspended: 'Suspendu',
  terminated: 'Résilié',
}

type StatusEditorCardProps = {
  initialStatus: ProtectionStatus
  pageState: ChangeContratStatusState
  dispatch: Dispatch<SelectStatusAction>
  isTerminationStatusUpdateDeadlinePassed: boolean
}

function StatusEditorCard({
  pageState,
  dispatch,
  initialStatus,
  isTerminationStatusUpdateDeadlinePassed,
}: StatusEditorCardProps) {
  const { selectedStatus, comment, effectDate } = pageState

  const allowedStatusesForSelection = useAllowedNextStatuses(initialStatus, isTerminationStatusUpdateDeadlinePassed)

  const onSubmitComment = (comment: string | undefined | null) =>
    dispatch({ type: 'update-comment', newComment: comment ?? '' })

  return (
    <div
      css={css`
        min-width: ${statusEditorFormMinWidth};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spaceBetweenFormFields};
        `}
      >
        <DropdownFormField
          label="Choisir le nouveau statut du contrat"
          size="small"
          value={selectedStatus ?? initialStatus}
          values={allowedStatusesForSelection}
          placeholder="Nouveau statut du contrat"
          labels={protectionStatusLabels}
          onChange={(newStatus) => {
            if (newStatus && isProtectionStatus(newStatus)) {
              dispatch({ type: 'select-status', newStatus, initialStatus })
            }
          }}
          aria-label="Sélectionner le nouveau statut du contrat"
        />

        <div
          css={css`
            display: flex;
            gap: ${spacing[60]};
          `}
        >
          <TextInputLabelWrapper label={"Date d'effet"}>
            <DatePicker
              size="small"
              value={effectDate}
              onChange={(newEffectDate) => dispatch({ type: 'update-effect-date', newEffectDate })}
              errorMessage={effectDate === null ? 'Ce champ est obligatoire' : undefined}
            />
          </TextInputLabelWrapper>

          {/*This component is always disabled and points to midnight - present just for UI purposes*/}
          <TextInputLabelWrapper label={"Heure d'effet"}>
            <TextField size="small" value="00:00" onChange={noop} disabled />
          </TextInputLabelWrapper>
        </div>
        {isTerminatedStatus(initialStatus, selectedStatus) ? (
          <TextInputLabelWrapper label={'Raison de résiliation'}>
            <Select
              aria-label="Raisons de résiliation"
              value={comment ?? ''}
              values={terminationReasonValues}
              onChange={onSubmitComment}
              size="small"
              placeholder="Raison de résiliation"
              labels={terminationReasonLabels}
            />
          </TextInputLabelWrapper>
        ) : (
          <TextInputLabelWrapper label={'Note'}>
            <ValidatedTextField
              size="small"
              value={comment ?? ''}
              mapper={mapper}
              onChange={onSubmitComment}
              placeholder="Descriptif"
            />
          </TextInputLabelWrapper>
        )}
      </div>
    </div>
  )
}

function useAllowedNextStatuses(
  currentStatus: ProtectionStatus,
  isTerminationStatusUpdateDeadlinePassed: boolean,
): ProtectionStatus[] {
  switch (currentStatus) {
    case 'not-started':
      return notStartedNextStatuses
    case 'active':
    case 'suspended':
      return activeAndSuspendedNextStatuses
    case 'terminated':
      return isTerminationStatusUpdateDeadlinePassed
        ? forbiddenTerminationUpdateStatusNextStatuses
        : terminatedNextStatuses
  }
}

const notStartedNextStatuses: ProtectionStatus[] = ['not-started', 'active', 'terminated']
const activeAndSuspendedNextStatuses: ProtectionStatus[] = ['active', 'suspended', 'terminated']
const terminatedNextStatuses: ProtectionStatus[] = ['active', 'suspended', 'terminated']
const forbiddenTerminationUpdateStatusNextStatuses: ProtectionStatus[] = ['terminated']

const mapper: ValidatedTypeMapper<string> = {
  inputType: 'text',
  format: (value) => value,
  formatPlaceholder: (value) => value,
  parse: (text) => {
    if (isEmptyOrBlankSpaces(text)) {
      return failure('Ce champ est obligatoire')
    }
    return success(text)
  },
}

function Header({
  contract,
  submitEnabled,
  isStatusEditable,
  goBack,
}: {
  contract: BackofficeContractDescription
  submitEnabled: boolean
  isStatusEditable: boolean
  goBack: () => void
}) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        margin-top: ${spacing[70]};
      `}
    >
      <LeftColumnHeader assetName={contract.displayName} contractStartTimestamp={contract.versions[0].startTimestamp} />
      <RightColumnHeader submitEnabled={submitEnabled} isStatusEditable={isStatusEditable} goBack={goBack} />
    </div>
  )
}

type GetProtectionScheduleUpdateOptions = {
  selectedStatus: UpdatedProtectionStatus
  effectDate: CalendarDate
  reason: string
}

type UpdateProtectionScheduleParams = {
  reactivationTimestamp: number | null
  suspensionTimestamp: number | null
  termination: ScheduledTermination | null
  comment: string
}

function getProtectionScheduleUpdate({
  selectedStatus,
  effectDate,
  reason,
}: GetProtectionScheduleUpdateOptions): UpdateProtectionScheduleParams {
  const effectTimestamp = calendarDateToDateTime(effectDate, PARIS).toMillis()

  switch (selectedStatus) {
    case 'active':
      return {
        comment: reason,
        reactivationTimestamp: effectTimestamp,
        suspensionTimestamp: null,
        termination: null,
      }
    case 'suspended':
      return {
        comment: reason,
        reactivationTimestamp: null,
        suspensionTimestamp: effectTimestamp,
        termination: null,
      }
    case 'terminated':
      if (!isTerminationReason(reason)) {
        throw new TechnicalError('Invalid termination reason', { context: { reason } })
      }
      return {
        comment: reason,
        reactivationTimestamp: null,
        suspensionTimestamp: null,
        termination: {
          timestamp: effectTimestamp,
          reason,
        },
      }
  }
}

type RightColumnHeaderProps = {
  submitEnabled: boolean
  goBack: () => void
  isStatusEditable: boolean
}

function RightColumnHeader({ submitEnabled, isStatusEditable, goBack }: RightColumnHeaderProps) {
  return (
    <div
      css={css`
        align-self: center;
        display: flex;
        gap: ${spacing[30]};
      `}
    >
      {isStatusEditable ? (
        <Button type="submit" variant="primary" size="medium" disabled={!submitEnabled}>
          Valider
        </Button>
      ) : (
        <Button type="submit" variant="primary" size="medium" onClick={goBack}>
          Retour
        </Button>
      )}
    </div>
  )
}

type LeftColumnHeaderProps = {
  assetName: string
  contractStartTimestamp: number
}

function LeftColumnHeader({ assetName, contractStartTimestamp }: LeftColumnHeaderProps) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[30]};
      `}
    >
      <Text variant="h3">Modifier le statut</Text>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Text variant="body2">{assetName}</Text>
        <Text variant="body2" color={colorTokens['color-text-base-basic']}>
          Créé le {formatTimestamp(contractStartTimestamp)}
        </Text>
      </div>
    </div>
  )
}

const terminationReasonLabels: Record<TerminationReason, string> = {
  'FA - Arrêt Activité': 'FA - Fake - Arrêt d’activité',
  'FI - Impayé': 'FI - Fake - Impayé',
  'FE - Erreur Contrat': 'FE - Fake - Erreur Contrat',
  'FL - A trouvé une autre assurance': 'FL - Fake - Trouvé une autre assurance',
  'FS - A jamais exercé (Stop)': 'FS - Fake - Jamais exercé',
  'FT - Test': 'FT - Fake - Test',
  'FAU - Autre': 'FAU - Fake - Autre raison',
  'FINS - Ne peut pas résilier son ancienne assurance': 'FINS - Fake - Impossible de résilier son ancienne assurance',
  'CA - Arrêt Activité': 'CA - Churn - Arrêt d’activité',
  'CI - Impayé': 'CI - Churn - Impayé',
  'CE - Erreur Contrat': 'CE - Churn - Erreur Contrat',
  'CL - A trouvé une autre assurance': 'CL - Churn - Trouvé une autre assurance',
  'CS - A jamais exercé (Stop)': 'CS - Churn - Jamais exercé',
  'CAU - Autre': 'CAU - Churn - Autre raison',
  'CINS - Ne peut pas résilier son ancienne assurance': 'CINS - Churn - Impossible de résilier son ancienne assurance',
  'FC - Ne peut pas être couvert': 'FC - Fake - Ne peut pas être couvert',
  'CC - Ne peut pas être couvert': 'CC - Churn - Ne peut pas être couvert',
}
